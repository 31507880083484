/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Grid from "@material-ui/core/Grid";
import Layout from "../components/layout/layout";
import Header from "../components/homepage/header";
import OurMission from "../components/homepage/ourMission";
import Newsletter from "../components/newsletter/newsletter";
import Portfolio from "../components/homepage/portfolio";
import DisruptiveSoftware from "../components/homepage/disruptiveSoftware";
import LargeScaleDeployment from "../components/homepage/largeScaleDeployment";
import ValueProposal from "../components/homepage/valueProposal";
import WorkWithUs from "../components/homepage/workWithUs";
// import Video from "../components/common/video";
import Temoignage from "../components/homepage/temoignage";
// import PlaceholderVideo from "../components/homepage/placeholder_video.png";
import News from "../components/homepage/news";
import Logos from "../images/logos.png";

const useStyles = makeStyles((theme) => ({
  regularRow: {
    padding: theme.layout.paddingRegular,
  },
  headerTitle: {
    color: "#FFF",
    fontWeight: "1000",
    position: "absolute",
    textAlign: "center",
    top: "calc(100vh / 80)",
    left: 0,
    width: "100%",
    marginTop: 150,
    fontSize: "6em",
  },
}));

const HomeFr = ({ data, pageContext: { slug, langKey } }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("lg"));
  const contents = data.allHomepageJson.nodes[0].homepage[langKey][0];

  return (
    <Layout slug={slug} langKey={langKey} siteMetadata={data.site.siteMetadata}>
      <Helmet>
        <title>
          Home | Cyclope.ai, logiciels d’IA pour améliorer la gestion des routes
        </title>
      </Helmet>
      <Grid
        item
        container
        direction="column"
        alignItems="stretch"
        style={{ position: "relative" }}
      >
        <div
          style={{
            position: "absolute",
            left: "15%",
            top: `calc((100vw / ${data.a9.childImageSharp.fluid.aspectRatio}) - (100vw * 0.7 / ${data.logos.childImageSharp.fluid.aspectRatio} / 2) ) `,
            textAlign: "center",
            width: "70%",
            minHeight: 133,
            backgroundColor: "#FFF",
            padding: "0 25px",
            zIndex: 800,
          }}
        >
          <img alt="logos" src={Logos} style={{ width: "100%" }} />
        </div>
        <Header
          line1={contents.mainTitle[0]}
          line2={contents.mainTitle[1]}
          data={data}
        />
        <OurMission
          image={data.photo1.childImageSharp.fluid}
          langKey={langKey}
          title={contents.headline1.title}
          content={contents.headline1.content}
          btnLabel={contents.headline1.btnLabel}
        />
        <DisruptiveSoftware
          langKey={langKey}
          title1={contents.headline2.title}
          title2={contents.headline2.content}
          buttons={[
            { uri: `/${langKey}/contact`, label: contents.headline2.btnLabel1 },
            { uri: `/${langKey}/about`, label: contents.headline2.btnLabel2 },
          ]}
          paddingTopPrct={7}
          alignContentCenter
        />

        {matches && (
          <Portfolio
            data={data}
            langKey={langKey}
            description={contents.portfolio.content}
            title={contents.portfolio.title}
            portfolioItems={contents.portfolio.items}
          />
        )}
        <LargeScaleDeployment
          image={data[`deployment${matches ? "L" : "S"}`].childImageSharp.fixed}
          title={contents.deployment.title}
          content={contents.deployment.content}
          items={contents.deployment.items}
        />
        <ValueProposal
          langKey={langKey}
          title={contents.value.title}
          content={contents.value.content}
          items={contents.value.items}
          btnLabel={contents.value.btnLabel}
        />
        <WorkWithUs
          image={data[`workwithus${matches ? "L" : "S"}`].childImageSharp.fixed}
          langKey={langKey}
          title={contents.workWithUs.title}
          content={contents.workWithUs.content}
          btnLabel={contents.workWithUs.btnLabel}
        />
        {/* {<Video
          srcMp4="http://cyclope.ai/static/decouvrez_cyclope1.mp4"
          srcWebm="http://cyclope.ai/static/decouvrez_cyclope1.webm"
          placeholderVideo={PlaceholderVideo}
          withBg
        >
          {contents.videoTitle1}
          <br />
          {contents.videoTitle2}
        </Video>} */}
        <Temoignage
          matches={matches}
          images={[
            data.temoignage1S.childImageSharp.fixed,
            data.temoignage2S.childImageSharp.fixed,
            data.temoignage3S.childImageSharp.fixed,
          ]}
          title={contents.temoignages.title}
          items={contents.temoignages.items}
        />
        {/* <News
          langKey={langKey}
          title={contents.news.title}
          btnLabel={contents.news.btnLabel}
        /> */}
        {matches && (
          <Newsletter
            langKey={langKey}
            className={classes.regularRow}
            btnLabel="Rester informé"
          >
            {<span>{contents.newsletter.title[0]}</span>}
          </Newsletter>
        )}
        {!matches && (
          <Newsletter langKey={langKey} className={classes.regularRow} />
        )}
      </Grid>
    </Layout>
  );
};

HomeFr.defaultProps = {
  data: PropTypes.object.isRequired,
};

export default HomeFr;

export const query = graphql`
  query HomeQueryFr {
    allHomepageJson {
      nodes {
        homepage {
          fr {
            mainTitle
            headline1 {
              content
              title
              btnLabel
            }
            headline2 {
              content
              title
              btnLabel1
              btnLabel2
            }
            portfolio {
              items {
                content
                title
                titleColor
              }
              title
              content
            }
            deployment {
              content
              title
              items {
                subTitle
                title
              }
            }
            value {
              btnLabel
              content
              items {
                content
                title
              }
              title
            }
            workWithUs {
              title
              content
              btnLabel
            }
            videoTitle1
            videoTitle2
            temoignages {
              title
              items {
                name
                position
                content
              }
            }
            news {
              btnLabel
              title
            }
            newsletter {
              title
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        author {
          twitter
        }
        siteTitle
        languages {
          defaultLangKey
          langs
        }
      }
    }
    a9: file(relativePath: { eq: "a9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    logos: file(relativePath: { eq: "logos.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo1: file(relativePath: { eq: "photo1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ecran1: file(relativePath: { eq: "ecran1.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecran2: file(relativePath: { eq: "ecran2.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecran3: file(relativePath: { eq: "ecran3.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ecran4: file(relativePath: { eq: "ecran4.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    deploymentL: file(relativePath: { eq: "deployment.jpg" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    deploymentS: file(relativePath: { eq: "deployment.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    workwithusL: file(relativePath: { eq: "workwithus.jpg" }) {
      childImageSharp {
        fixed(width: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    workwithusS: file(relativePath: { eq: "workwithus.jpg" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    temoignage1S: file(relativePath: { eq: "temoignage1.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    temoignage2S: file(relativePath: { eq: "temoignage2.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    temoignage3S: file(relativePath: { eq: "temoignage3.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
